import React from "react";
import {
    useDataGrid,
    EditButton,
    ShowButton,
    DeleteButton,
    List,
} from "@refinedev/mui";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useMany } from "@refinedev/core";

export const TubList = () => {
    const { dataGridProps } = useDataGrid();

    const { data: tubData, isLoading: tubIsLoading } = useMany({
        resource: "tubs",
        ids: dataGridProps?.rows?.map((item: any) => item?.tub_id) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });

    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "tub_id",
                flex: 1,
                headerName: "Tub",
                minWidth: 300,
            },
            {
                field: "birthdate",
                flex: 1,
                headerName: "Birthdate",
                minWidth: 200,
            },
            {
                field: "myco_type",
                flex: 1,
                headerName: "Myco Type",
                minWidth: 200,
            },
            {
                field: "status",
                flex: 1,
                headerName: "Status",
                minWidth: 200,
            },
            {
                field: "id",
                headerName: "Id",
                type: "number",
                minWidth: 50,
            },
            {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row.id} />
                            <ShowButton hideText recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [tubData?.data],
    );

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight />
        </List>
    );
};
